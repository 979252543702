.light-theme {
    --primary-color: #E15201;
    --secondary-color: #0065B7;
    --color-yellow: #D99F2B;
    --color-green: #98B212;
    --color-green-dark: #02C242;
    --color-green-light: #3DCCB4;
    --color-blue: #4595E5;
    --color-blue-dark: #3956E5;
    --color-purple: #9B50E5;
    --color-pink: #D94CD9;
    --color-pink-light: #D94C92;
    --background-color: #FCFCFD;
    --color-neutral: #505566;
    --color-grey-light: #D9D9D9;
    --text-color-quad: #111215;
    --primary-color-filter: invert(40%) sepia(57%) saturate(2959%) hue-rotate(1deg) brightness(101%) contrast(106%);
    --filter-color-black: brightness(0%);
    --font-size: 16px;
    --spacing-unit: 8px;
    --border-radius: 4px;
    --box-shadow-boxes: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    --sideBart-item: #505566;
    --sidebar-color: #FEF2EB;
    --filter-icon-sidebar: none;
    --primary-color-filter-active: invert(64%) sepia(89%) saturate(6229%) hue-rotate(6deg) brightness(93%) contrast(101%);
}

.dark-theme {
    --primary-color: #E15201;
    --secondary-color: #0065B7;
    --color-yellow: #D99F2B;
    --color-green: #98B212;
    --color-green-dark: #02C242;
    --color-green-light: #3DCCB4;
    --color-blue: #4595E5;
    --color-blue-dark: #3956E5;
    --color-purple: #9B50E5;
    --color-pink: #D94CD9;
    --color-pink-light: #D94C92;
    --background-color: #222223;
    --color-neutral: #505566;
    --color-grey-light: #D9D9D9;
    --text-color-quad: #ffffff;
    --primary-color-filter: invert(40%) sepia(57%) saturate(2959%) hue-rotate(1deg) brightness(101%) contrast(106%);
    --filter-color-black: brightness(0%);
    --font-size: 16px;
    --spacing-unit: 8px;
    --border-radius: 4px;
    --box-shadow-boxes: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    --sideBart-item: #ffffff;
    --sidebar-color: #111215;
    --filter-icon-sidebar: brightness(0) invert(1);
    --primary-color-filter-active: invert(64%) sepia(89%) saturate(6229%) hue-rotate(6deg) brightness(93%) contrast(101%);


}