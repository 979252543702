.sidebar-schedule {
  position: relative;
  width: 90vh;
  height: 100vh;
  margin: -40px -40px -40px 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden; /* Impede que o conteúdo "vaze" durante a transição */
  background-color: white; /* Cor de fundo (opcional) */
}

.sidebar-schedule-enter {
  width: 500px !important; /* Largura final ao abrir */
}

.header-schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  padding: 20px;

  .title-schedule {
    font-weight: 600;
    font-size: 18px;
  }

  .description-schedule {
    font-size: 14px;
    text-transform: capitalize;
    color: #4b5563;
  }

  div {
    align-items: center;
  }

  .close-schedule {
  }

  button {
    border: none;
    background: transparent;
  }
}

.body-schedule {
  position: relative;
  height: 85%;

  .schedule-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    padding: 20px;

    .title {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .schedules {
    display: flex;
    height: 94%;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    padding: 20px;
    border-top: 1px solid #e5e7eb;

    .empty-schedule,
    .loading {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.image {
  height: 40px;
  width: 40px;
  color: #9ca3af;
  margin-bottom: 10px;
}

.schedule {
  display: flex;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: box-shadow 0.2s;

  .info-schedule {
    width: 80%;
    .user-schedule {
    }
    .month-schedule {
    }
    .hour-schedule {
    }
    .value-schedule {
    }
  }

  .status-schedule {
  }
}

.schedule:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* hover:shadow-md */
}
