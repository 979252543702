.chart-container-dash{
    border: 1px solid #00000012;
    padding: 30px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: max-content;
}

.chart-container-dash .card{
    border: none;
}

.chart-container-dash span{
    font-weight: 600;
}

.title-page-list span{
    font-size: 28px;
}

.dashboard-page{
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-gap: 30px;
}

.dash-area{
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-gap: 30px;
    width: 100% !important;
}

.cards-dash-area{
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 16px;
}

.cards-dash-area-2{
    display: grid;
    grid-template-columns: 1fr 30em;
    grid-gap: 16px;
    height:  300px;
}
