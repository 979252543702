.logout-button-container{
    display: grid;
    grid-template-rows: max-content 1fr;
    align-items: center;
    position: relative;
    gap: 6px;
}

.logout-button-container.closed{
    display: grid;
    grid-template-rows: 1fr !important;
}

.logout-button label{
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
}

.logout-button button{
    background-color: #FF5C00 !important;
    border: none;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 28px;
    width: 28px;
    padding: 0 !important;
}

.p-button-label{
    margin-top: -1px;
}

.p-menu.p-component{
    margin-top: -10px;
}

.p-menu.p-component ul{
    padding-left: 0px !important;
    margin-bottom: 0px !important;
}

.p-menu.p-component a{
    text-decoration: none !important;
}