.cardchart-container{
    border: 1px solid #00000012;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 13.75em;
    height: 7.50em;
    display: grid;
    grid-template-rows: max-content 1fr;
    color: var(--color-neutral);
}

.cardchart-icon{
    position: relative;
    background-color: #F2F3F5;
    padding: 10px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.cardchart-area{
    padding: 10px 20px;
}

.cardchart-value-title{
    font-size: 14px;
    font-weight: 600;
    
}

.cardchart-value-total{
    font-size: 20px;
    font-weight: 600;
}

.cardchart-value-title-icon{
    font-size: 12px;
    margin-top: 2px;
}

.cardchart{
    width: 100%;
}