.sidebar {
  width: 16rem;
  background-color: var(--sidebar-color);
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) -5px 5px 15px;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  text-overflow: ellipsis;
  height: 100vh;
  display: grid;
  grid-template-rows: 6em 1fr 3em;
  text-align-last: center;
}

.sidebar:not(.small) h3 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

.sidebar:not(.small) .userMenu-user-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar.closed {
  width: 80px;
  background-color: var(--sidebar-color);
  padding: 13px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sidebar h3 {
  color: var(--text-color-quad);
  font-size: 14px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar ul li {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  width: 100%;
}

.title-side-bar-image {
  height: 50px;
}

.title-side-bar {
  display: flex;
  align-items: center;
  position: relative;
  place-content: center;
  color: var(--sideBart-item);
  margin-bottom: 50px;

  img {
    height: 40px;
  }

  h3 {
    font-family: "Righteous", sans-serif;
    font-size: 40px;
    font-weight: 400 !important;
    margin-left: 10px;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking {
  animation: blink 1s infinite;
}

.sidebar-change-size {
  position: absolute;
  right: -30px;
  padding: 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}

.icon-side-bar-click {
  background-color: var(--sidebar-color);
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.35);
  padding: 7px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: var(--sideBart-item);
}

.icon-side-bar-click:hover {
  color: var(--primary-color);
}

.sidebar-icon-li {
  display: flex;
  justify-items: center;
  width: 100%;
  padding: 10px 10px;

  span {
    margin-left: 20px;
  }
}

.sidebar-icon-li:hover {
  background-color: #fce2d7 !important; /* hover:bg-gray-200 */
  color: #ff5c00 !important;
}

.sidebar-icon-li span {
  margin-top: 8px;
  font-size: 15px !important;
  color: var(--sideBart-item);
  text-decoration: none;
}

.sidebar-items a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.sidebar-icon-li.active span {
  color: #ff5c00 !important;
  font-weight: 600;
}

.sidebar-container {
  height: 100vh;
  position: relative;
}

.icon-large {
  fill: none !important; /* Garante que o preenchimento é removido */
  stroke: #000; /* Define a cor do contorno */
}

.icon-large.active {
  fill: none !important;
  stroke: #ff5c00;
}
