.p-field {
    margin-bottom: 15px;
}

.p-field label {
    display: block;
    margin-bottom: 5px;
}

.p-field input, .p-field .p-password input {
    width: 100%; 
    padding: 5px;
    border-radius: 8px;
}

.p-field-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.p-field-checkbox label {
    margin-left: 5px;
}

.login-links {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.login-links a {
    color: #9c9c9c;
    text-decoration: none;
}

.login-links a:hover {
    text-decoration: underline;
}

.p-button-primary {
    align-self: center; 
    width: 100%;
    background-color: #FF5C00;
    border-radius: 8px;
    border: none;
}

.p-password{
    display: inline;
}

.pi{
    color: #495057;
    position: absolute;
    right: 12px;
    z-index: 10;
}

.span-icon-input{
    display: block !important;
}