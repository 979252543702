.button {
  padding: 8px 18px;
  border-radius: 10px;
  transition: background-color 0.3s;
  border: none;
  display: flex;
  gap: 10px;
}

.button-primary,
.fc-today-button,
.fc-listWeek-button {
  background-color: #ff5c00 !important;
  color: #fcfcfd !important;
  opacity: 1 !important;
  border: none !important;
}

.button-secondary,
.fc-next-button,
.fc-prev-button,
.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
  background-color: #fcfcfd !important;
  color: #ff5c00 !important;
  border: none !important;
}

.button-primary:hover,
.fc-today-button:hover,
.fc-listWeek-button:hover {
  background-color: #f88e53 !important; /* hover:bg-gray-200 */
  color: #fcfcfd !important;
}

.button-secondary:hover,
.fc-next-button:hover,
.fc-prev-button:hover,
.fc-dayGridMonth-button:hover,
.fc-timeGridWeek-button:hover,
.fc-timeGridDay-button:hover {
  background-color: #fce2d7 !important; /* hover:bg-gray-200 */
  color: #ff5c00 !important;
}
