.app-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: var(--background-color);
    color: var(--text-color-quad);
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow:auto;
    height: 100%;
}

.title-page-list{
    font-size: 18px;
    font-weight: 600;
}