.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #ff5c00;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .radio-input {
    label {
      margin-right: 20px;
      align-items: center;
    }
    /* Personaliza a bolinha do rádio */
    input[type="radio"] {
      appearance: none; /* Remove o estilo padrão */
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 15px; /* Tamanho da bolinha */
      height: 15px;
      border-radius: 50%; /* Forma de círculo */
      border: 2px solid #ddd; /* Cor da borda */
      background-color: white; /* Cor de fundo */
      cursor: pointer;
      transition: background-color 0.3s, border-color 0.3s;
      margin-right: 5px;
    }

    /* Quando o rádio for selecionado */
    input[type="radio"]:checked {
      background-color: #ff5c00; /* Cor de fundo ao selecionar */
      border-color: #ff5c00; /* Cor da borda ao selecionar */
    }

    /* Bolinha interna quando o rádio estiver selecionado */
    input[type="radio"]:checked::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: white; /* Cor da bolinha interna */
    }
  }
}

.label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 0.25rem;
}

.input,
.select {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #d1d5db;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input:focus,
.select:focus {
  border-color: #6366f1;
  outline: none;
  box-shadow: 0 0 0 1px rgba(99, 102, 241, 0.5);
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.button-cancel {
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  color: #374151;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
}

.button-cancel:hover {
  background-color: #e5e7eb; /* hover:bg-gray-200 */
}

.button-submit {
  padding: 0.5rem 1rem;
  background-color: #4f46e5; /* bg-indigo-600 */
  color: #ffffff; /* text-white */
  border-radius: 0.5rem; /* rounded-lg */
  transition: background-color 0.3s;
}

.button-submit:hover {
  background-color: #4338ca; /* hover:bg-indigo-700 */
}
