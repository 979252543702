.login-container {
    display: flex;
    height: 100vh;
}

.left-panel {
    flex: 2;  
    background: url('./../../assets/bg3.jpg') no-repeat center center;
    background-size: cover;
}

.right-panel {
    flex: 1;  
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2a2a2a;
}

.login-form {
    width: 300px;
    background: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    margin: 0px 16px !important;
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.p-field {
    margin-bottom: 15px;
}

.p-field label {
    display: block;
    margin-bottom: 5px;
}

.p-field input, .p-field .p-password input {
    width: 100%; 
    padding: 5px;
    border-radius: 8px;
}

.p-field-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.p-field-checkbox label {
    margin-left: 5px;
}

.login-links {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.login-links a {
    color: #9c9c9c;
    text-decoration: none;
}

.login-links a:hover {
    text-decoration: underline;
}

.p-button-primary {
    align-self: center; 
    width: 100%;
    background-color: #FF5C00;
    border-radius: 8px;
    border: none;
}

.p-password{
    display: inline;
}