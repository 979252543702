.court-page {
  height: 100%;
  width: 100%;
  display: flex;

  .body {
    width: 100%;
  }

  .title {
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #8d93a5;
    }
  }

  .custom-form {
    width: 200px;
    margin: 20px;
  }

  .custom-select {
    height: 50px;
  }

  .header {
    border-radius: 6px;
    border: 1px solid #00000026;
    margin-bottom: 20px;
  }

  .no-border {
    border: none;
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1px;
    padding: 15px;
    border-bottom: 1px solid #00000026;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;

    .loading {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .custom-footer {
    border-top: 1px solid #00000026;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 15px;
    gap: 10px;
  }

  .custom-button {
    margin-left: 5px;
  }
}

.card {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-weight: 600;
      font-size: 18px;
    }

    .status {
      padding: 6px 10px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .type {
    font-size: 14px;
    color: #4b5563;
    text-transform: capitalize;
  }

  .value {
    padding-top: 10px;
    display: flex;
    align-items: center;
    color: #4b5563;
    font-size: 16px;
  }

  .ocupation {
    padding-top: 10px;

    select {
      width: 100%;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      border: transparent;
      border-radius: 10px;
      height: 32px;
    }
  }
}

/* Modal */
.modal-container {
  border-radius: 1rem;
  padding: 1rem;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Grid */
.grid-layout {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .grid-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Input e Selects */
.input-container {
  position: relative;
}

.input-container input,
.input-container select {
  width: 100%;
  padding-left: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d1d5db;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  height: 32px;
}

.input-icon {
  position: absolute;
  inset-y: 0;
  left: 0;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.input-container input:focus,
.input-container select:focus {
  border-color: #a3bffa;
  box-shadow: 0 0 0 4px rgba(164, 194, 255, 0.5);
}

/* Filtro Limpar */
.clear-filters-btn {
  font-size: 0.875rem;
  color: #4b5563;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  transition: color 0.2s, background-color 0.2s;
}

.clear-filters-btn:hover {
  color: #1f2937;
  background-color: #f9fafb;
}
