.scheduling-screen {
  display: flex;
  flex-direction: column;

  .scheduling-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-size: 28px;
      font-weight: 600;
    }
  }
}

.loading {
  display: flex;
  margin-top: 30%;
  align-items: center;
  justify-content: center;
}

.fc-event-main {
  background: #ff5c00;
}

.fc-day-today {
  background: #d1d1d1 !important;
}

.fc-col-header-cell-cushion {
  color: #000 !important;
  text-transform: capitalize;
}

.fc-daygrid-day-number,
.fc-event-time,
.fc-event-title {
  color: #000 !important;
}

.fc-daygrid-event-dot {
  color: red !important;
  background: red !important;
}
