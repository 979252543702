body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  text-decoration: none !important;
}

div,
button,
a {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  text-decoration: none !important;
}

.App {
  height: 100%;
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem vertical */
  height: 8px; /* Altura da barra de rolagem horizontal */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do fundo da área de rolagem */
  border-radius: 10px; /* Bordas arredondadas do track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Cor da parte interativa (thumb) */
  border-radius: 10px; /* Bordas arredondadas do thumb */
  transition: background 0.3s; /* Suaviza a transição de cor */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor do thumb quando em hover */
}

/* Para scroll horizontal */
::-webkit-scrollbar-horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb:active {
  background: #333; /* Cor do thumb quando pressionado */
}
