.icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    fill: currentColor;
    vertical-align: middle;
  }
  
.icon-court::before {
    content: url('../Icons/icon-court.svg');
}

.icon-dash::before {
    content: url('../Icons/icon-dash.svg');
}

.icon-troph::before {
    content: url('../Icons/icon-troph.svg');
}

.icon-user::before {
    content: url('../Icons/icon-user.svg');
}

.icon-confirm::before {
    content: url('../Icons/icon-confirm.svg');
}

.icon-doubleArrowLeft::before {
    content: url('../Icons/icon-doubleArrowLeft.svg');
}

.icon-doubleArrowRight::before {
    content: url('../Icons/icon-doubleArrowRight.svg');
}

.icon-filter::before {
    content: url('../Icons/icon-filter.svg');
}

.icon-plus::before {
    content: url('../Icons/icon-plus.svg');
}

.icon-search::before {
    content: url('../Icons/icon-search.svg');
}

.icon-singleArrowLeft::before {
    content: url('../Icons/icon-singleArrowLeft.svg');
}

.icon-singleArrowRight::before {
    content: url('../Icons/icon-singleArrowRight.svg');
}

.icon-x::before {
    content: url('../Icons/icon-x.svg');
}

.icon-small {
    width: 16px;
    height: 16px;
}
  
.icon-medium {
    width: 24px;
    height: 24px;
}
  
.icon-large {
    width: 32px;
    height: 32px;
}
  